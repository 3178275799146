@font-face {
  font-family: 'NeueKabel';
  font-weight: 800;
  src: local('NeueKabel'), url(./fonts/NeueKabel-ExtraBold.otf) format('opentype');
}

@font-face {
  font-family: 'NeueKabel';
  src: local('NeueKabel'), url(./fonts/NeueKabel-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'NeueKabel';
  font-weight: 900;
  src: local('NeueKabel'), url(./fonts/NeueKabel-Black.otf) format('opentype');
}

@font-face {
  font-family: 'NeueKabel';
  font-weight: 400;
  src: local('NeueKabel'), url(./fonts/NeueKabel-Book.otf) format('opentype');
}

